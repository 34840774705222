* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "AR One Sans", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  background-color: #f6f6f6;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: rgb(9, 0, 46);
  font-size: 1.1rem;
  font-weight: 600;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}

.nav-logo-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25rem;
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.home-banner-container {
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 35rem;
  background-color: #9fd5ff;
  background-image: url("./Assets/background-home.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
}
.texto-home {
  display: flex;
  position: relative;
  margin-top: 2rem;
  align-items: center;
  justify-content: space-around;
}
#minha-div {
  position: relative; /* Define posição relativa no elemento pai */
}
.floating-button {
  position: fixed;
  bottom: 2rem; /* Ajuste conforme necessário para a posição desejada */
  right: 40%; /* Ajuste conforme necessário para a posição desejada */
  z-index: 1000; /* Garante que o botão esteja sempre acima do conteúdo da página */
  transform: rotate(-45deg);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  background-color: #007e99;
  border: none;
  cursor: pointer;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 2.5rem;
  animation: pulo 0.45s ease-in-out infinite;
}

.botao-telefone {
  border-radius: 50%;
}

@keyframes pulo {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.floating-button:hover {
  background-color: white;
  color: #007e99;
}

.modal-container {
  position: fixed;
  z-index: 1000; /* Garante que o botão esteja sempre acima do conteúdo da página */
  bottom: 7rem;
  right: 25%;
  transition: 0.2s;
  font-size: 1.3rem;
  color: #007e99;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.modal-content a {
  text-decoration: none;
  color: #007e99;
}
.modal-content p {
  text-decoration: none;
  color: rgb(180, 0, 0);
}

.modal {
  border-radius: 2rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-form-section {
  max-width: 25rem;
  display: flex; /* Torna a div um container flexível */
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
  align-items: center; /* Centraliza verticalmente o conteúdo */
  flex-direction: column;
  flex: 1;
  margin: auto;
  margin-right: 2rem;
  font-size: 1.2rem;
  text-align: justify;
  background-color: #bbdfffc5;
  border-radius: 15px; /* Define o raio das bordas arredondadas */
  padding: 1rem;
}

.home-form-section button {
  width: 300px;
}
.home-titulo-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2rem;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #ffffff;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
  background-color: rgba(255, 255, 255, 0.546);
  border-radius: 15px; /* Define o raio das bordas arredondadas */
  padding: 1rem;
}
.primary-text-home {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #3a2d2d;
  margin: 1.5rem 0rem;
  padding: 1rem;
}

.primary-text-servico {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #007e99;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  text-decoration: none;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: white;
  color: #007e99;
}

.instagram-button {
  padding: 1rem 2.5rem;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 1rem;
}
.whatsapp-button {
  padding: 1rem 2.5rem;
  background: #25D366;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.instagram-button svg {
  margin-right: 0.75rem;
  font-size: 1.5rem;
}
.whatsapp-button svg {
  margin-right: 0.75rem;
  font-size: 1.5rem;
}
.instagram-button:hover {
  background-color: #ffffff;
  color: #ffffff;
}
.whatsapp-button:hover {
  background-color: #ffffff;
  color: #25D366;
}
.about-section-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-section-text-container {
  margin-top: 0;
  flex: 1;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}
.primary-subheading {
  font-weight: 700;
  color: #007e99;
  font-size: 2rem;
  margin: auto;
  text-align: center;
  margin-top: 1rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

.facility-wrapper {
  margin-bottom: 1rem;
}

.about-section-text-container > :first-child {
  margin-left: 1rem;
}

.facility-option {
  margin-top: 2rem;
}

.facility-head {
  margin: auto;
  text-align: center;
  margin-top: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
  margin-top: 1rem;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #4056a1;
  margin-bottom: 1rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
  margin-bottom: 3rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem;
  display: flex;
}
.footer-logo-container {
  max-width: 300px;
}
.footer-icons {
  margin-top: 2.5rem;
  margin-left: 1.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.5rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns a {
  margin: 0.25rem 0rem;
  width: 300px;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
  text-decoration: none;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}

.work-section-wrapper {
  background-image: url("./Assets/background-3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  height: 30rem;
  position: relative;
}

.work-section-wrapper h1 {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  font-size: 2.5rem;
  color: #ffffff;
  margin: 1.5rem 0rem;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 1rem;
  margin-bottom: 8rem;
  /* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; */
}

.work-section-wrapper p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  font-size: 1.5rem;
  color: #ffffff;
  margin: 1.5rem 0rem;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  text-align: left;
  margin-left: 1rem;
  /* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; */
}
.lares-section-wrapper {
  background-image: url("./Assets/background-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 35rem;
  position: relative;
}
.lares-section-wrapper h1 {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 30rem;
  font-size: 2.5rem;
  color: #ffffff;
  margin: 1.5rem 0rem;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  text-align: right;
  margin-bottom: 4rem;
  margin-right: 1rem;
  /* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; */
}
.lares-section-wrapper p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  font-size: 1.5rem;
  color: #ffffff;
  margin: 1.5rem 0rem;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  text-align: right;
  margin-right: 1rem;
  /* text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; */
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.consultants-section-wrapper {
  background-color: #4056a1;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 1200px !important;
  color: white;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 17rem;
  min-height: 20rem;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  margin-bottom: 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.work-section-info img {
  width: 10rem;
}

.servico-info {
  width: 17rem;
  min-height: 30rem;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  margin-bottom: 2rem;
}
.servico-info h2 {
  margin: 1rem 0rem;
}

.servico-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.servico-info img {
  width: 10rem;
}

.container-parceiros {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 1000px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
  }
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .nav-logo-container {
    max-width: 400px;
  }
}
@media (max-width: 800px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
  }
  .nav-logo-container {
    max-width: 400px;
    margin-top: 1.15rem;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-container {
    margin: 0;
  }
  .about-section-text-container {
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .primary-text {
    text-align: center;
    margin: auto;
  }
  .instagram-button {
    margin: auto;
    text-align: center;
  }

  .home-banner-container,
  .about-section-container {
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-form-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
    margin-top: 1rem;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .home-form-heading {
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
    margin-bottom: 1rem;
  }
  .home-titulo-section {
    justify-content: center;
    align-items: center;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  /* .consultants-section-wrapper {
    margin-top: 5rem !important;
  } */
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .footer-section-one {
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-section-columns {
    margin: 0;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
  .modal-container {
    right: 10%;
  }
}
